<template>
    <div>
      <div class="all-height d-flex justify-center">
        <div class="all-width">
          <v-card :loading="loading" :disabled="loading" loader-height="2" class="shadow-off">
            <v-card-text class="pb-0">
  
              <div class="">
                <div class="d-flex">
                  <p class="mb-1">Home</p>
                  <p class="px-2 mb-1">/</p>
                  <p class="mb-1"></p>
                </div>
                <div class="d-flex">
                  <p class="black--text font-weight-bold text-h5">Virtual Concierge</p>
                  <v-tooltip bottom content-class="tooltip-bottom" >
                    <template v-slot:activator="{ on, attrs }"> 
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            class="shadow-off ml-1" icon @click="refreshData()"><v-icon
                            size="24">mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Refresh</span>
                  </v-tooltip>
                </div>
              </div>
  
  
              <div class="d-flex align-center">
                <lb-string label="Search" width="30vh" prependiconinner="mdi-magnify" outlined hidedetails
                  v-model="seachList"></lb-string>
                <v-spacer></v-spacer>
                <v-btn v-if="$nova.hasRight(right, 'add')" rounded color="black"
                  @click="queriesDilog = true; queriesObject = {}; error = {};" elevation="0" small height="4vh"
                  class="white--text mr-2">
                  <v-icon class="mr-1">mdi-plus</v-icon>New
                </v-btn>
              </div>
            </v-card-text>
            <v-card-text>
  
  
              <lb-datatableaction v-if="queriesData.length>0" :headers="queriesHeader" :tabledata="queriesData" itemsperpage="" searchterm=""
                :bulkActions="false">
  
                <template v-slot:tbody>
                  <tr v-for="(item, index) in queriesData" :key="item._id" class="cursor-pointer  "
                    :class="{ 'low-opacity': item.status === false }">
                    <template v-for="header in queriesHeader">
                      <td v-if="header.visible && header.value !== 'action'" :key="header.value" class="px-2 single-line">
                        
                        <div class="" v-if="header.value ==='stage'">
                          <v-chip  label v-if="item.stage === 1" color="lightgreen" class="green--text">Close</v-chip>
                          <v-chip  label v-else color="lightblue" class="blue--text">Open</v-chip>
                        </div>
                        <span v-else-if="header.value ==='no'">
                          {{ index+1 }}
                        </span>
                        <span v-else>
                          {{ item[header.value] }}
                        </span> 
  
                      </td>
                    </template>
                    <td class="d-flex align-center px-3 single-line border-right border-left action-data">
                      <div class="d-flex align-center justify-center">
                        
                        <v-tooltip bottom content-class="tooltip-bottom" >
                          <template v-slot:activator="{ on, attrs }"> 
                            <v-btn @click="
                              $nova.gotoLink({ path: 'support/' + item._id })
                              "  v-bind="attrs"
                              v-on="on" class="view-on-hover-item-d mx-0 px-0" icon><v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>View</span>
                        </v-tooltip>

                        <!-- <Conversation heading="Conversation" width="600" :loading="loading" :recurring="true"
                          :recurringText="recurringContent" :notify="false" :projectid="item._id" :getApi="getURL"
                          :sendApi="sendURL" /> -->
                      </div>
                    </td>
                  </tr>
                </template>
              </lb-datatableaction>

              <div class="d-flex align-center justify-center" v-else>
                NO DATA
              </div>
  
  
            </v-card-text>
          </v-card>
        </div>
      </div>


      <lb-drawer v-model="queriesDilog" :heading="queriesObject._id ? 'Update' : 'Add'"
            width="600" :loading="queriesloading">
            <template v-slot:body>
                <div>
                  <v-row>
                    <v-col cols="12" class="my-0 py-1">
                      <p class="pa-0 ma-0  text-subtitle-1">Request</p>
                      <lb-string label="" :isRequired="true" placeholder="Enter request" v-model="queriesObject.issue" :error="errors['issue']" />
                    </v-col>
                    <v-col cols="12" class="my-0 py-1">
                      <p class="pa-0 ma-0  text-subtitle-1">Description</p>
                      <lb-textarea v-model="queriesObject.description" label="" placeholder="Enter request descprition" :error="errors['description']" />
                    </v-col>
                  </v-row>
                </div>
            </template>
            <template v-slot:actions>
                <v-switch :label="enableSwitch === true ? 'Enable' : ' Disable'" class="mt-0" v-model="enableSwitch"
                    @onchange="toggleSwitch(locationdata)" inset hide-details></v-switch>
                <v-spacer></v-spacer>
                <v-btn small color="black" class="white--text" height="3.5vh" v-if="queriesObject._id" @click="productUpdate()">Update</v-btn>
                <v-btn small color="black" class="white--text" height="3.5vh" v-else @click="createQuerie()">Create</v-btn>
            </template>
        </lb-drawer>

      </div>
  </template>
  
  <script>
  // import Conversation from "../common/Conversation.vue";
  export default {
    name: "helpdesk_list",
    data() {
      return {
        right: "helpdesk",
        getURL: "/v2/bot/analysis/getbotconversation/",
        sendURL: "/v2/bot/analysis/addbotconversation",
        recurringContent: "Is it possible to append comment in requirements sdsdfs sdfsdf sdfsd asdsadawd ",
        loading: false,
        errors: {},
        queriesObject: {},
        queriesDilog: false,
        queriesloading: false,
        queriesHeader: [
          { text: "No", value: "no", sortable: false, filterable: false, visible: true },
          { text: "Request", value: "issue", sortable: false, filterable: false, visible: true },
          { text: "Description", value: "description", sortable: false, filterable: false, visible: true },
          { text: "Type", value: "type", sortable: false, filterable: false, visible: true, align: "center" },
          { text: "Stage", value: "stage", sortable: false, filterable: false, visible: true, align: "center" },
          { text: "Action", value: "action", sortable: false, filterable: false, visible: true, align: "center" },
        ],
        seachList: null,
        queriesData: [],
      };
    },
    components: {
      // Conversation,
    },
    created() {
      this.refreshData();
    },
    activated() {
      this.refreshData();
    },
    props: {
      project: {
        type: String,
        default: "",
      },
      type: {
        type: String,
        default: "",
      },
      typenot: {
        type: String,
        default: "",
      },
    },
    methods: {
      refreshData() {
        this.loading = true;
        let nfltr = { modulename: this.$route.meta.modulename };
        if (this.type) nfltr.type = this.type;
        if (this.typenot) nfltr.typenot = this.typenot;
        if (this.project) nfltr.project = this.project;
        this.axios
          .post("/v2/helpdesk/queries/getqueriesbyclient", { filter: nfltr })
          .then((dt) => {
            this.queriesData = dt.data.data;
          })
          .catch((err) => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      },
  
      createQuerie() {
        this.queriesloading = true;
        // console.log(this.$route.mata.modulename, "this.$route.mata.modulename");
        this.queriesObject.modulename = this.$route.meta.modulename;
        this.axios
          .post("/v2/helpdesk/queries/add", { data: this.queriesObject })
          .then((dt) => {
            if (dt.data.status === "success") {
              this.refreshData();
              this.$store.commit("sbSuccess", "New Query Added successfully");
              this.queriesDilog = false;
            } else {
              if (dt.data.data.length > 0) {
                if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
              }
              throw new Error(dt.data.message || "Error Add Query");
            }
          })
          .catch((err) => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          })
          .finally(() => {
            this.queriesloading = false;
          });
      },
      QuerieUpdate() {
        this.loading = true;
        this.queriesObject.modulename = this.$store.state.modulename;
        this.axios
          .post("/v2/helpdesk/queries/edit/" + this.queriesObject._id, {
            data: this.queriesObject,
          })
          .then((dt) => {
            if (dt.data.status === "success") {
              this.refreshData();
              this.$store.commit("sbSuccess", "New Query Added successfully");
              this.refreshData();
              this.queriesDilog = false;
            } else {
              if (dt.data.data.length > 0) {
                if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
              }
              throw new Error(dt.data.message || "Error Add Query");
            }
          })
          .catch((err) => {
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      },
    },
  };
  </script>
  